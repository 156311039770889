  .footer {
    background-color: #3f3c37;
    color: white;
    padding: 20px 0;
  }

  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: start;
    padding: 20px;
    flex-wrap: wrap; /* Ensures responsiveness */
  }
  
.footlogo {
  align-items: center;
  display: flex;
}
  
.footer-section h3 {
    margin-bottom: 10px;
  }
  
.footer-section ul,
.footer-section p {
    list-style-type: none;
    padding: 0;
  }
  
.footer-logo {
    align-items: center;
    align-content: center;
    height: 80px;
  }
  
.footer-bottom {
    text-align: center;
    padding: 10px 20px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin-bottom: 20px;
    }
  }
  
  /* Additional styling for text and links */
  .footer-section ul li,
  .footer-section p {
    margin-bottom: 5px;
  }
  
  .footer-section ul li:hover,
  .footer-section p:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  