.contact-form-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form h2 {
    margin-bottom: 2rem;
    color: #333;
    text-align: center;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    border: none;
    border-bottom: 1px solid #ddd;
    outline: none;
    background: transparent;
    transition: border-color 0.2s;
  }
  
  .form-group label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #666;
    pointer-events: none;
    transition: 0.2s ease all;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-bottom: 2px solid #F59E1D;
  }
  
  .form-group input:focus ~ label,
  .form-group textarea:focus ~ label,
  .form-group input:valid ~ label,
  .form-group textarea:valid ~ label {
    top: -20px;
    font-size: 12px;
    color: #F59E1D;
  }
  
  .submit-button {
    align-self: flex-end;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #F59E1D;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #e08c0b;
  }
  
  @media (max-width: 600px) {
    .contact-form-container {
      width: 100%;
      padding: 1rem;
    }
  }