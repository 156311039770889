.header {
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
  background: rgba(255, 255, 255, 255);
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  transition: background-color 0.3s ease;
  z-index: 1000;
  font-family: 'Open Sans', sans-serif;
}

.logo {
  cursor: pointer;
  height: 60px;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 10px;
  position: relative; /* This is necessary for the absolute positioning of the dropdown */
}

.nav-link {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-link:hover {
  color: #ffd700;
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.nav-item:hover .dropdown-content {
  display: block;
}
