/* src/index.css */

body {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
}

.padded {
  padding-right: 8%;
  padding-left: 8%; 
}

.padded-15 {
  padding-right: 15%;
  padding-left: 15%;
}

.padded-25 {
  padding-right: 25%;
  padding-left: 25%;
}

.spacer {
  height: 85px; /* Or min-height if you want it to be expandable */
  background-color: #FFFFFF; /* Ensuring the space is white */
  /* Add padding or margin as needed */
}

.spacer-mini {
  height: 40px; /* Or min-height if you want it to be expandable */
  background-color: #FFFFFF; /* Ensuring the space is white */
  /* Add padding or margin as needed */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
