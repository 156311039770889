.rauction {
    margin-top: 6rem;
}

.about {
    margin-top: 6rem;
}

.landing-container {
    position: relative; /* Keeps the entire container relative */
    overflow: hidden; /* Ensures no overflow from the container */
}

.main-section {
    background-image: url('../../public/reverseauction.jpg');
    background-size: cover;
    background-position: center center;
    height: 50vh; /* Reduced by header height */
    position: relative; /* This is required for absolute positioning of children */
}
  
.auc-section {
    background-image: url('../../public/electric.jpg');
    height: 30vh; /* Reduced by header height */
    position: relative; /* This is required for absolute positioning of children */
}

.auc-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Changed to 0.5 for visibility, adjust as necessary */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 15%;
    padding-left: 15%; 
    text-align: left;
  }
  
  .auc-title {
    font-size: 4rem;
    color: white;
    margin: 0;
    justify-items: left;
  }

.auc-subtitle {
    color: white; 
    font-size: 1.5rem;
    margin-right: 13rem;
    padding: 0;
}

.explanation {
    font-size: 3.5rem;
    font-weight: 200;
}

.explanation-title {
    margin: 0rem;
}

.explanation-subtitle {
    font-size: 1.4rem;
    font-weight: 300;
}

.bolding {
    font-weight: 400;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Creates two columns */
    grid-template-rows: 1fr 1fr; /* Creates two rows */
    gap: 20px; /* Adjust the gap between items */
    width: 100%; /* Set to the width of the container, adjust as needed */
    max-width: 1300px;
    margin: 0 auto; /* Center the grid container if smaller than the parent */
  }

  .check {
    width: 25px;
    height: 20px;
  }
  
  .grid-item {
    padding: 20px; /* Adjust padding */
    text-align: left; /* Center text horizontally */
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center;
    font-weight: 300;
    font-size: 21px;
    gap: 20px;
  }

.orange-background {
    background-image: url('../../public/orangeline.png');
    background-size: cover;
    background-position: center center;
    height: 100vh;
    width: 100%;

}


.auc-works-title {
    font-size: 4rem;
    margin: 0;
    margin-top: 4%;
}

.auc-works-subtitle {
    padding: 0;
    font-weight: 300;
    font-size: 1.1rem;
    margin-bottom: 3rem;
}

.auc-works-benefits {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.auc-works-benefits-box img {
    width: 75px;
    height: 65px;
}

.auc-works-benefits-box-text {
    font-weight: 300;
    padding: 0 1rem;
}

.auc-works-benefits-box-header-title {
    font-weight: 400;
    font-size: 25px;
}
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .grid-container {
      grid-template-columns: 1fr; /* Stack items in one column on smaller screens */
    }
  }
  

.explain-text-commercial {
    font-size: 20px; 
    font-weight: 300;
    text-align: left;
}

.advertised-section-commercial {
    display: flex;
    align-items: center;
    justify-content: space-around; /* Adjusts space around content */
    /* padding: 40px 20px; */
    background-color: #F59E1D; /* or any color you want */
  }
  
  .advertised-text-commercial {
    flex-basis: 40%; /* Takes up half the space of the container */
    color: white;
    font-size: 25px;
  }
  
  .advertised-map-commercial {
    flex-basis: 60%; /* Takes up the other half */
  }
  
  .advertised-map-commercial img {
    max-width: 100%; /* Ensures the image is responsive */
    height: auto; /* Maintains aspect ratio */
  }