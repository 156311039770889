/* Containers */
.container {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 50px); /* Adjust the height accordingly */
}

.background-image {
    height: 100%;
    background-size: cover;
    background-position: center;
    margin-top: 5.5rem;
    position: relative;
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
  }
  
  .background-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay to enhance text visibility */
    z-index: 1;
  }
  
  .nonprofit, .business, .industry, .education, .hospitality {
    position: relative;
  }
  
  .nonprofit {
    background-image: url('../../public/nonprofit.png');
  }
  
  .business {
    background-image: url('../../public/smallbusiness.webp');
  }
  
  .industry {
    background-image: url('../../public/industry.png');
  }
  
  .education {
    background-image: url('../../public/education.jpg');
  }
  
  .hospitality {
    background-image: url('../../public/hospitality.jpg');
  }


  .obligation {
    background-image: url('../../public/workfromhome.webp');
  }

  .replay {
    background-image: url('../../public/liveauction.jpeg');
  }

  .coverage {
    background-image: url('../../public/coveragemap.jpg');
  }

  .equalterms {
    background-image: url('../../public/equalterms.jpg');
  }


  .category {
    position: absolute;
    width: 80%; /* Limiting width to ensure it stays within bounds */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centering category */
    text-align: center; /* Center align the text */
    z-index: 2;
  }
  
  .category-header {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 18%;
    z-index: 3; /* Ensure buttons are above the overlay */
    box-sizing: border-box;
  }
  
  .category-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-weight: 300;
  }
  .category-title {
    margin-bottom: 20px; /* Space between title and text */
    color: white;
    font-size: 35px;
    font-weight: bold;
  }

  
  /* Category Buttons */
  .category-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure this is above the background image */
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 2rem;
  }
  
  /* Category Buttons */
  .category-button {
    background-color: rgba(255, 255, 255, 1.0);
    border: 1px black solid;
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 10px;
    width: 200px; /* This is the long side */
    height: 225px; /* This is the short side */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .category-button:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  /* This is used to identify the active category. You can use JavaScript to add this class to the active button */
  .category-button.active {
    background-color: rgba(0, 123, 255, 1.0);
    color: #fff;
  }