.landing-container {
  position: relative; /* Keeps the entire container relative */
  overflow: hidden; /* Ensures no overflow from the container */
}

.hero-section {
  background-image: url('../../public/cityscape.jpg');
  background-size: cover;
  background-position: center center;
  height: 80vh; /* Reduced by header height */
  position: relative; /* This is required for absolute positioning of children */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Changed to 0.5 for visibility, adjust as necessary */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 8%;
  padding-left: 8%; 
}

.hero-title {
  font-size: 4rem;
  color: white;
  margin: 0;
  justify-items: left;
}

.contact-landing {
  display: flex;
  gap: 20px;

}

.contact-text-box {
  color: white;
  font-size: 20px;
  
}

.contact-text {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
}

.cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 0.5rem;
  align-self: center;
}

.cta-button:hover {
  background-color: #0056b3;
}

.info-section {
  background-color: white;
  padding: 40px 20px;
}

.advertised-section {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Adjusts space around content */
  /* padding: 40px 20px; */
  background-color: #3f3c37; /* or any color you want */
}

.advertised-text {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  flex-basis: 40%; /* Takes up half the space of the container */
  color: white;
  font-size: 40px;
}

.advertised-map {
  flex-basis: 60%; /* Takes up the other half */
}

.advertised-map img {
  max-width: 100%; /* Ensures the image is responsive */
  height: auto; /* Maintains aspect ratio */
}
