.about {
    margin-top: 6rem;
}

.landing-container {
    position: relative; /* Keeps the entire container relative */
    overflow: hidden; /* Ensures no overflow from the container */
}
  
.main-back {
    background-image: url('../../public/electric.jpg');
    background-size: cover;
    background-position: center center;
    height: 50vh; /* Reduced by header height */
    position: relative; /* This is required for absolute positioning of children */
}

.main-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Changed to 0.5 for visibility, adjust as necessary */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 8%;
    padding-left: 8%; 
  }
  
.main-title {
    font-size: 4rem;
    color: white;
    margin: 0;
    justify-items: left;
}

.main-subtitle {
    color: white; 
    font-size: 1.5rem;
}

.explain {
    text-align: left;
}

.explain-title {
    font-size: 4rem;
    font-weight: 200; 
    margin: 0;
}

.explain-subtitle {
    font-size: 4rem;
    font-weight: 600; 
    margin: 0;
}

.explain-subsubtitle {
    font-size: 20px;
    font-weight: 400;
}

.explain-text {
    font-size: 20px; 
    font-weight: 300;
}

.explain-box {
    display: flex;
    width: 100%;
    justify-content: center;
}

.explain-box-item {
    display: flex;
    flex: 1;
}

.explain-box img {
    height: 25px;
}

.explain-box-item p {
    margin-left: 0.6rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.4rem 0;
}

.nav-item p {
    font-size: 1rem;
}

.linked {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Inherits the color from the parent element */
}